import {
  IPaginationResponse,
  IResortBasicStatusPartial, IResortNearby, IResortPageInfo, IResortPathInfo, ISnowHistoryItem, OpenFlag,
} from 'api/resort/types';
import { IPaginableResponse } from 'types';

export type RegionGroupId = string;

/**
 * Short region info to cache for building pathes and create id <-> slug mappings
 */
export interface IRegionShort {
  /**
   * Region title
   */
  title: string;
  /**
   * Region untranslated english title
   */
  title_original: string;
  /**
   * country
   */
  country: string;
  /**
   * Region slug
   */
  slug: string;
  /**
   * Resort uuid
   */
  uuid: string;

  /**
   * Group ID
   */
  group: RegionGroupId;

  /**
   * Ad banner region code
   * Should present in responses for resort and region pages by uuid
   */
  adCode?: string;

  /**
   * Region state
   */
  state?: string;

  /**
   * Region country code
   */
  country_code?: string;

  /**
   * Region external domain
   * If specified, means region is not hosted on current domain and should have external link
   */
  domain?: string;

  /**
   * Logical region
   * If "true" then consists of resorts logically connected and not geographically.
   */
  isLogical?: boolean;
}

/**
 * Group info
 */
export interface IRegionGroup extends IRegionShort {
  /**
   * Resort title
   */
  title: string;
  /**
   * Title for region group "More info" link
   */
  showMoreTitle?: string;
  /**
   * Resort uuid
   */
  uuid: RegionGroupId;
}

export interface IRegionAllPathsInfo {
  regions: Array<IRegionShort>;
  groups: Array<IRegionGroup>;
}

export type IResortShortReport = IResortPathInfo & IResortBasicStatusPartial & { webcam?: IWebcamItem };

export interface IRegionResorts {
  partners?: Array<IResortShortReport>;
  other: Array<IResortShortReport>;
}

export type RegionResortStatus = OpenFlag | 'partner';

export type IRegionResortsPerStatus = Record<RegionResortStatus, IRegionResortsPaginated>;

export type IRegionResortsPerStatusPages = Record<RegionResortStatus, IRegionResortsPaginated[]>;

export type IRegionResortsPaginated = IPaginableResponse<IResortShortReport>;

export type IRegionResortsNonPaginated = Pick<IPaginableResponse<IResortShortReport>, 'data'>;

export type INearbyResort = Omit<IResortShortReport, 'region'> & { latitude: number, longitude: number, region: IRegionShort };

export type INearbyResortWDistance = INearbyResort & { distance: number };

export interface IRelatedForResort {
  related?: Array<IRegionShort>;
  nearby?: Array<IResortNearby>;
}

export interface IRelatedForRegion {
  related?: Array<IRegionShort>;
}

export interface IRegionCam {
  resort: IResortPathInfo;
  openFlag: OpenFlag;
  openingDate?: string;
  closingDate?: string;
  webcam: IWebcamItem;
}

export interface IRegionRamResponse { data: Array<IRegionCam>, pagination: IPaginationResponse }

export type IRegionFAQ = {
  descriptionRegion: string;
  items: Array<IItemFAQ>;
};

export type IRegionStats = {
  depths: { base?: number, middle?: number, summit?: number },
  depthsPercent:{ base?: number, middle?: number, summit?: number },
  averageSnowfallCm?: number
};

export type IItemFAQ = {
  uuid: string;
  title: string;
  description: string;
};

export enum WebcamType {
  Feratel = 2,
  Webcam360 = 3,
}

export interface IWebcamItem {
  uuid: string;
  title: string;
  date: string;
  image: string;
  thumbnail: string;
  videoStream?: string;
  webcamType?: WebcamType;
  snow?: ISnowHistoryItem;
  summitSnowDepth?: number;
  isFeatured?: boolean;
}

export interface IRegionFeaturedResort {
  resort: IResortPathInfo;
  details: IResortPageInfo & { webcam: IWebcamItem };
}
