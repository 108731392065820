import { BoxWithShadow } from 'components/blocks/BoxWithShadow';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';

const PARTNER_ID = '3e08';

const WeSkiWidget = ({ destinationId, withTopCopy }: { destinationId: string, withTopCopy?:boolean }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.weski.com';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <BoxWithShadow>
      {withTopCopy
      && (
      <p className={styles.copyWrapper}>
        Now you can actually book your dream ski holiday and not just plan it!
        <br />
        <br />

        We’ve partnered with WeSki to bring you fully customisable ski trips in just a few clicks.
        Choose your destination and add everything you need: Flights, transfer, accommodations,
        ski pass and even ski rentals and lessons lessons. All in one place…
      </p>
      )}
      <div
        id="weski-widget"
        data-partner={PARTNER_ID}
        data-destination-id={destinationId}
      />
    </BoxWithShadow>
  );
};

export default WeSkiWidget;
