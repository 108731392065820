import { OneTrustCategories } from 'onetrust/constants';
import { useOTStatus } from 'onetrust/useOTStatus';
import { IS_SSR, isProductionServer } from 'ots-constants';
import { useEffect } from 'react';
import { Diagnostics } from 'util/diagnostics';
import { useOptAnonWrapper } from 'util/useOptAnonWrapper';
import { useReadyOnWebOnly } from 'util/useReadyOnWebOnly';
import { youtubeParser } from 'components/home/PlayerYouTube';
import { useServerOneTrustCookieGroups } from 'settings/SettingsContext';

let id: number = 0;
const prefix = 'OPT_ANON_IFRAME_';

export const useOneTrustIframe = (
  el: HTMLElement | null,
  src: string,
  title: string,
  category: OneTrustCategories,
  attributes?: Record<string, string>,
  forceOneTrust?: boolean,
) => {
  const preApproved = useServerOneTrustCookieGroups();
  const oneTrust = useOptAnonWrapper();
  const oneTrustStatus = useOTStatus();
  const webReady = useReadyOnWebOnly();

  const youtubeId = youtubeParser(src);
  const useOneTrust = (forceOneTrust || !youtubeId) && !(preApproved?.[category]);
  const finalUrl = youtubeId ? `https://www.youtube-nocookie.com/embed/${youtubeId}?autoplay=1` : src;

  if (!isProductionServer && !IS_SSR) {
    Diagnostics.debug('Youtube detection', src, youtubeId, finalUrl);
  }

  useEffect(() => {
    if (finalUrl && (oneTrust || !useOneTrust) && category && el && webReady) {
      // eslint-disable-next-line no-param-reassign,no-plusplus
      el.id = el.id || `${prefix}${id++}`;
      // eslint-disable-next-line no-param-reassign
      el.innerHTML = '';
      const attributesStr: string[] = [];

      if (attributes) {
        Object.keys(attributes).forEach((k) => {
          attributesStr.push(`${k}="${attributes[k]}"`);
        });
      }
      const iframe = `<iframe src="${finalUrl}" ${attributesStr.join(' ')} title="${title}" allow="autoplay" />`;
      if (!isProductionServer) {
        Diagnostics.debug('OneTrust.InsertHtml', iframe);
      }
      if (useOneTrust) {
        oneTrust!.InsertHtml(
          iframe,
          `${el.id}`,
          () => Diagnostics.debug('Added iframe with OpnAnon', finalUrl),
          {},
          category,
          true,
        );
      } else {
        Diagnostics.debug('Added iframe without OpnAnon', finalUrl);
        // eslint-disable-next-line no-param-reassign
        el.innerHTML = iframe;
      }
    }
  }, [oneTrust, webReady, finalUrl, oneTrustStatus, useOneTrust, el]);
};
