import { YouTubeEmbed } from 'components/home/PlayerYouTube/YouTubeEmbed';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { ReactComponent as SvgPlay } from 'public/assets/icons/play.svg';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { useReadyOnWebOnly } from 'util/useReadyOnWebOnly';
import styles from './styles.module.scss';

/**
 * @see https://stackoverflow.com/a/8260383
 */
export function youtubeParser(url: string) {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : false;
}

export const PlayerYouTube = ({
  urlYouTube,
  className,
}: {
  urlYouTube: string,
  className?: string,
}) => {
  const { t } = useOTSTranslation('common');
  const [isOpenMobile, toggleIsOpenMobile] = useState<boolean>(false);
  const embedId = youtubeParser(urlYouTube);
  const ready = useReadyOnWebOnly();

  const openPlayerMobile = useCallback(() => {
    toggleIsOpenMobile(true);
  }, []);

  return (
    <div className={classNames(styles.player, className)}>
      {embedId && (
        <>
          <button
            type="button"
            className={classNames(styles.watch, isOpenMobile ? styles.isOpenMobile : '')}
            onClick={openPlayerMobile}
          >
            <SvgPlay />
            {t('common.watchVideo')}
          </button>
          {ready && (
          <YouTubeEmbed
            embedId={embedId}
            className={isOpenMobile ? styles.isOpenMobile : ''}
          />
          )}
        </>
      )}
    </div>
  );
};
